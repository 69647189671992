














































































































































import { Component, Vue } from 'vue-property-decorator'
import {
    apiIntegralGoodsDel,
    apiIntegralGoodsLists,
    apiIntegralGoodsStatus
} from '@/api/application/integral_mall'
import { RequestPaging } from '@/utils/util'
import LsDialog from '@/components/ls-dialog.vue'
import LsPagination from '@/components/ls-pagination.vue'
@Component({
    components: {
        LsDialog,
        LsPagination
    }
})
export default class IntegralGoods extends Vue {
    formData = {
        name: '',
        type: '',
        status: ''
    }

    pager = new RequestPaging()
    getList(page?: number): void {
        page && (this.pager.page = page)
        this.pager.request({
            callback: apiIntegralGoodsLists,
            params: this.formData
        })
    }
    handleReset() {
        Object.keys(this.formData).forEach(key => {
            this.$set(this.formData, key, '')
        })
        this.getList()
    }
    handleStatus(value: number, id: number) {
        apiIntegralGoodsStatus({
            id,
            status: value
        }).then(() => {
            this.getList()
        })
    }

    handleDelete(id: number) {
        apiIntegralGoodsDel({ id }).then(() => {
            this.getList()
        })
    }

    created() {
        this.getList()
    }
}
